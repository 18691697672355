import React, { useState } from "react";
import "../Form.css";
import Modal from "./Modal";

const FormBlog = () => {
  const [step, setStep] = useState(1);
  const [blogStyle, setBlogStyle] = useState("");
  const [socialIntegration, setSocialIntegration] = useState("");
  const [seoFeatures, setSeoFeatures] = useState("");
  const [additionalFeatures, setAdditionalFeatures] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div class="form-container" data-aos="fade">
      {step === 1 && (
        <>
          <p>
            <strong>Pergunta 1:</strong> Qual estilo e tema você deseja para seu
            blog?
            <br />
            <em>
              Selecione o estilo que melhor se alinha com a identidade do seu
              blog.
            </em>
          </p>
          <select
            value={blogStyle}
            onChange={(e) => setBlogStyle(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="minimal">Minimalista</option>
            <option value="modern">Moderno</option>
            <option value="classic">Clássico</option>
            <option value="custom">Outro (Personalizado)</option>
          </select>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}

      {step === 2 && (
        <>
          <p>
            <strong>Pergunta 2:</strong> Você precisa de integração com redes
            sociais?
            <br />
            <em>
              Informe se você deseja integrar seu blog com plataformas de redes
              sociais.
            </em>
          </p>
          <select
            value={socialIntegration}
            onChange={(e) => setSocialIntegration(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}

      {step === 3 && (
        <>
          <p>
            <strong>Pergunta 3:</strong> Quais funcionalidades de SEO e
            categorias de posts você deseja?
            <br />
            <em>
              Defina as funcionalidades de SEO necessárias e as categorias que
              você deseja incluir.
            </em>
          </p>
          <textarea
            value={seoFeatures}
            onChange={(e) => setSeoFeatures(e.target.value)}
            placeholder="Descreva as funcionalidades de SEO e categorias..."
            rows="4"
            required
          />
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}

      {step === 4 && (
        <>
          <p>
            <strong>Pergunta 4:</strong> Você precisa de recursos adicionais,
            como sistema de comentários ou newsletters?
            <br />
            <em>
              Informe se você deseja incluir recursos adicionais ao seu blog.
            </em>
          </p>
          <select
            value={additionalFeatures}
            onChange={(e) => setAdditionalFeatures(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="comments">Sistema de Comentários</option>
            <option value="newsletters">Newsletters</option>
            <option value="both">Ambos</option>
            <option value="none">Nenhum</option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button type="submit" onClick={handleSubmit}>
            Próximo
          </button>
        </>
      )}
      <Modal
        Idform={2}
        showModal={showModal}
        setShowModal={setShowModal}
        atb1={blogStyle}
        atb2={socialIntegration}
        atb3={seoFeatures}
        atb4={additionalFeatures}
      />
    </div>
  );
};

export default FormBlog;
