import React, { useState } from "react";
import detectDevice from "../../components/detectDevice";
import "./../Form.css";

export default function Modal({
  showModal,
  setShowModal,
  atb1,
  atb2,
  atb3,
  atb4,
  atb5,
}) {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");

  const formResponse = {
    atb1,
    atb2,
    atb3,
    atb4,
    atb5,
  };

  const Url = detectDevice();
  const handleModalSubmit = (e) => {
    e.preventDefault();
    window.location.href = `${Url}text=Olá,%20respondi%20o%20formulário%20e%20tenho%20os%20seguinte%20requisitos%20para%20o%20desenvolvimento%20de%20soluções%20para%20minha%20empresa:%20%20${encodeURIComponent(
      formResponse.atb1
    )}%20%20${encodeURIComponent(
      formResponse.atb2
    )}%20%20${encodeURIComponent(
      formResponse.atb3
    )}%20%20${encodeURIComponent(
      formResponse.atb4
    )}%20%20${encodeURIComponent(
      formResponse.atb5
    )}%20%20Nome:%20${encodeURIComponent(
      name
    )}%20%20Email:%20${encodeURIComponent(mail)}`;
    setShowModal(false);
  };
  return (
    showModal && (
      <div className="modal" data-aos="fade-up">
        <div className="modal-content">
          <h2>Preencha seus dados</h2>
          <input
            type="text"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="E-mail"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
          <button onClick={() => setShowModal(false)}>Cancelar</button>
          <button onClick={handleModalSubmit}>Enviar</button>
        </div>
      </div>
    )
  );
}
