import React, { useState } from "react";
import "../Form.css";
import detectDevice from "../../components/detectDevice";
import Modal from "./Modal";

const FormCommerce = () => {
  const [step, setStep] = useState(1);
  const [platform, setPlatform] = useState("");
  const [ecommerceNeeds, setEcommerceNeeds] = useState("");
  const [paymentIntegration, setPaymentIntegration] = useState("");
  const [physicalNeeds, SetPhysicalNeeds] = useState("");
  const [customFeatures, setCustomFeatures] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  return (
    <div class="form-container" data-aos="fade">
      {step === 1 && (
        <>
          <p>
            <strong>Pergunta 2:</strong> Em qual plataforma você deseja operar
            sua loja?
            <br />
            <em>
              A escolha da plataforma é crucial para definir a melhor estratégia
              de desenvolvimento e tecnologia.
            </em>
          </p>
          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="web">Web (Loja Online)</option>
            <option value="mobile">Aplicativo Móvel</option>
            <option value="physical">Loja Física com Sistema de Suporte</option>
            <option value="cross-platform">Multi-plataforma</option>
          </select>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 2 && platform === "web" && (
        <>
          <p>
            <strong>Pergunta 3:</strong> Quais funcionalidades você precisa para
            sua loja online?
            <br />
            <em>
              A escolha das funcionalidades permitirá que seu e-commerce atenda
              perfeitamente às suas necessidades.
            </em>
          </p>
          <select
            value={ecommerceNeeds}
            onChange={(e) => setEcommerceNeeds(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="advanced">
              Catálogo de Produtos e Carrinho de Compras suporte a Múltiplos
              Métodos de Pagamento
            </option>
            <option value="custom">
              opções acima + funcionalidades Personalizadas
            </option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 2 && platform === "mobile" && (
        <>
          <p>
            <strong>Pergunta 3:</strong> Quais funcionalidades você precisa para
            seu app?
            <br />
            <em>
              A escolha das funcionalidades permitirá que seu app atenda
              perfeitamente às suas necessidades.
            </em>
          </p>
          <select
            value={ecommerceNeeds}
            onChange={(e) => setEcommerceNeeds(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="advanced">
              Catálogo de Produtos e Carrinho de Compras suporte a Múltiplos
              Métodos de Pagamento
            </option>
            <option value="custom">
              opções acima + funcionalidades Personalizadas
            </option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 2 && platform === "cross-platform" && (
        <>
          <p>
            <strong>Pergunta 3:</strong> Quais funcionalidades você precisa para
            seu e-commerce e app?
            <br />
            <em>
              A escolha das funcionalidades permitirá que seu e-commerce e seu
              aplicativo móvel atenda perfeitamente às suas necessidades.
            </em>
          </p>
          <select
            value={ecommerceNeeds}
            onChange={(e) => setEcommerceNeeds(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="advanced">
              Catálogo de Produtos e Carrinho de Compras suporte a Múltiplos
              Métodos de Pagamento
            </option>
            <option value="custom">
              opções acima + funcionalidades Personalizadas
            </option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 2 && platform === "physical" && (
        <>
          <p>
            <strongs>Pergunta 2:</strongs>
            Quais integrações são necessárias entre sua loja física e o sistema
            de suporte?
            <br />
            <em>
              Entender as integrações necessárias ajuda a criar um sistema que
              complementa e melhora as operações da loja física.
            </em>
          </p>
          <select
            value={physicalNeeds}
            onChange={(e) => SetPhysicalNeeds(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="automation">Automações de tarefas</option>
            <option value="web-scraping">Coleta dados na Web</option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button type="submit" onClick={handleSubmit}>
            Enviar
          </button>
        </>
      )}
      {step === 3 && ecommerceNeeds === "advanced" && (
        <>
          <p>
            <strong>Pergunta 4:</strong> Quais métodos de pagamento você
            gostaria de integrar?
            <br />
            <em>
              A integração de diferentes métodos de pagamento pode melhorar a
              conversão de vendas.
            </em>
          </p>
          <select
            value={paymentIntegration}
            onChange={(e) => setPaymentIntegration(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="credit-card">Cartão de Crédito</option>
            <option value="pix">PIX</option>
            <option value="paypal">PayPal</option>
            <option value="boleto">Boleto Bancário</option>
            <option value="all">Todos os Acima</option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button type="submit" onClick={handleSubmit}>
            Enviar
          </button>
        </>
      )}
      {step === 3 && ecommerceNeeds === "custom" && (
        <>
          <p>
            <strong>Pergunta 4:</strong> Quais métodos de pagamento você
            gostaria de integrar?
            <br />
            <em>
              A integração de diferentes métodos de pagamento pode melhorar a
              conversão de vendas.
            </em>
          </p>
          <select
            value={paymentIntegration}
            onChange={(e) => setPaymentIntegration(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="credit-card">Cartão de Crédito</option>
            <option value="pix">PIX</option>
            <option value="paypal">PayPal</option>
            <option value="boleto">Boleto Bancário</option>
            <option value="all">Todos os Acima</option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 4 && ecommerceNeeds === "custom" && (
        <>
          <p>
            <strong>Pergunta 5:</strong> Quais funcionalidades personalizadas
            você deseja para sua loja?
            <br />
            <em>
              Especifique as funcionalidades que você gostaria de adicionar.
              Quanto mais detalhes, melhor poderemos atender às suas
              necessidades.
            </em>
          </p>
          <textarea
            value={customFeatures}
            onChange={(e) => setCustomFeatures(e.target.value)}
            placeholder="Descreva as funcionalidades personalizadas desejadas..."
            rows="4"
            required
          />
          <button onClick={handlePreviousStep}>Anterior</button>
          <button type="submit" onClick={handleSubmit}>
            Enviar
          </button>
        </>
      )}
      <Modal
        Idform={1}
        showModal={showModal}
        setShowModal={setShowModal}
        atb1={platform}
        atb2={ecommerceNeeds}
        atb3={paymentIntegration}
        atb4={physicalNeeds}
        atb5={customFeatures}
      />
    </div>
  );
};

export default FormCommerce;
