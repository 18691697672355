import React from "react";
import "./hero.css";
const Hero = () => (
  <section className="hero" data-aos="fade-up">
    <div className="container">
      <h1>
        Desenvolvimento de Software Personalizado para Impulsionar Seu Negócio
      </h1>
      <p>
        Com nossa expertise em desenvolvimento de software, criamos soluções sob
        medida que impulsionam seu negócio para o próximo nível. Seja através de
        aplicativos inovadores, plataformas robustas ou sistemas personalizados,
        nossas soluções incluem também integrações avançadas com Inteligência
        Artificial, que automatizam processos, melhoram a tomada de decisões e
        oferecem insights valiosos para manter sua empresa à frente da
        concorrência.
      </p>

      <a href="#contato" className="cta-button">
        Solicite uma Consultoria Gratuita
      </a>
    </div>
  </section>
);

export default Hero;
