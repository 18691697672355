import React from "react";
import "./about.css"
const About = () => (
  <section id="sobre" className="about" data-aos="fade-up">
    <div className="container">
      <h2>Sobre Nós</h2>
      <p>
        Somos uma empresa de desenvolvimento de software dedicada a transformar
        ideias inovadoras em soluções digitais de alta qualidade. Nossa equipe
        de especialistas trabalha com tecnologias de ponta para oferecer
        produtos que atendem às necessidades específicas de cada cliente.
      </p>
      <p>
        Temos um histórico comprovado de sucesso em diversos setores. Nosso
        compromisso é entregar projetos que não apenas atendam, mas superem as
        expectativas, ajudando nossos clientes a alcançarem seus objetivos de
        negócio.
      </p>
    </div>
  </section>
);

export default About;
