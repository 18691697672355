import React, { useState } from "react";
import "../Form.css";
import Modal from "./Modal";
const FormStartup = () => {
  const [step, setStep] = useState(1);
  const [startupStage, setStartupStage] = useState("");
  const [mvpNeeds, setMvpNeeds] = useState("");
  const [marketScalability, setMarketScalability] = useState("");
  const [mvpSpecs, setMvpSPecs] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div class="form-container" data-aos="fade">
      {step === 1 && (
        <>
          <p>
            <strong>Pergunta 1:</strong> Em qual estágio sua startup se
            encontra?
            <br />
            <em>Selecione o estágio que melhor descreve sua startup.</em>
          </p>
          <select
            value={startupStage}
            onChange={(e) => setStartupStage(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="idea">Ideia</option>
            <option value="mvp">MVP (Produto Mínimo Viável)</option>
            <option value="scaling">Escalando</option>
            <option value="other">Outro</option>
          </select>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}

      {step === 2 && (
        <>
          <p>
            <strong>Pergunta 2:</strong> Você precisa de ajuda para desenvolver
            seu MVP?
            <br />
            <em>
              Informe se você necessita de suporte no desenvolvimento do seu
              MVP.
            </em>
          </p>
          <select
            value={mvpNeeds}
            onChange={(e) => setMvpNeeds(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 3 && mvpNeeds === "yes" && (
        <>
          <p>
            <strong>Pergunta 3:</strong> Quais os serviços principais da sua
            startup
          </p>
          <textarea
            value={mvpSpecs}
            onChange={(e) => setMvpSPecs(e.target.value)}
            placeholder="Descreva quais os serviços principais da sua startup..."
            rows="4"
            required
          />
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 3 && mvpNeeds === "no" && (
        <>
          <p>
            <strong>Pergunta 3:</strong> Qual outra solução você está buscando
            para sua startup?
          </p>
          <textarea
            value={mvpSpecs}
            onChange={(e) => setMvpSPecs(e.target.value)}
            placeholder="Descreva a solução que está buscando para sa startUp..."
            rows="4"
            required
          />
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}
      {step === 4 && (
        <>
          <p>
            <strong>Pergunta 4:</strong> Como você vê a escalabilidade do seu
            mercado-alvo?
            <br />
            <em>
              Descreva suas expectativas em relação à escalabilidade e ao
              crescimento do mercado.
            </em>
          </p>
          <textarea
            value={marketScalability}
            onChange={(e) => setMarketScalability(e.target.value)}
            placeholder="Descreva suas expectativas de escalabilidade..."
            rows="4"
            required
          />
          <button onClick={handlePreviousStep}>Anterior</button>
          <button type="submit" onClick={handleSubmit}>
            Enviar
          </button>
        </>
      )}
      <Modal
        Idform={2}
        showModal={showModal}
        setShowModal={setShowModal}
        atb1={startupStage}
        atb2={mvpNeeds}
        atb3={marketScalability}
        atb4={mvpSpecs}
      />
    </div>
  );
};

export default FormStartup;
