import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Form.css";

const Form = () => {
  const [businessType, setBusinessType] = useState("");
  const navigate = useNavigate();

  const handleNextStep = () => {
    switch (businessType) {
      case "commerce":
        navigate("/form/commerce");
        break;
      case "corporate":
        navigate("/form/corporate");
        break;
      case "startup":
        navigate("/form/startup");
        break;
      case "blog":
        navigate("/form/blog");
        break;
      case "other":
        navigate("/form/other");
        break;
      default:
        alert("Por favor, selecione um tipo de negócio.");
        break;
    }
  };

  return (
    <div className="form-container">
      <h2>Descubra o Serviço Ideal para Seu Negócio</h2>
      <>
        <p>
          <strong>Pergunta 1:</strong> Qual é o tipo do seu negócio?
          <br />
          <em>
            Isso nos ajudará a entender as necessidades gerais da sua empresa.
          </em>
        </p>
        <select
          value={businessType}
          onChange={(e) => setBusinessType(e.target.value)}
          required
        >
          <option value="">Selecione</option>
          <option value="commerce">Loja</option>
          <option value="corporate">Corporativo</option>
          <option value="startup">Startup</option>
          <option value="blog">Blog/Site Informativo</option>
          <option value="other">Outro</option>
        </select>
        <button onClick={handleNextStep}>Próximo</button>
      </>
    </div>
  );
};

export default Form;
