import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Hero from "./components/hero";
import About from "./components/about";
import Services from "./components/services";
import ProductizedSoftware from "./components/productizedSoftware";
import Contact from "./components/contact";
import Footer from "./components/footer";
import FormCall from "./components/formCall";
import Form from "./pages/Form";
import FormCommerce from "./pages/componentsForm/Commerce";
import FormCorporate from "./pages/componentsForm/Corporate";
import FormStartup from "./pages/componentsForm/Startup";
import FormBlog from "./pages/componentsForm/Blog";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="App" data-aos="fade">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <About />
              <Services />
              <ProductizedSoftware />
              <FormCall />
              <Contact />
            </>
          }
        />
        <Route path="/form" element={<Form />} />
        <Route path="/form/commerce" element={<FormCommerce />} />
        <Route path="/form/corporate" element={<FormCorporate />} />
        <Route path="/form/startup" element={<FormStartup />} />
        <Route path="/form/blog" element={<FormBlog />} />
        <Route path="/form/other" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
