import React, { useState, useEffect } from "react";
import "./header.css";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [currentWord, setCurrentWord] = useState("Desenvolvimento");
  const [isTyping, setIsTyping] = useState(true);
  const [isErasing, setIsErasing] = useState(false);

  useEffect(() => {
    const words = ["Desenvolvimento", "Soluções"];
    const wordIndex = words.indexOf(currentWord);
    const nextWord = words[(wordIndex + 1) % words.length];

    let typingTimer;
    if (isTyping) {
      typingTimer = setTimeout(() => {
        setIsTyping(false);
        setIsErasing(true);
      }, 4000);
    } else if (isErasing) {
      typingTimer = setTimeout(() => {
        setCurrentWord(nextWord);
        setIsErasing(false);
        setIsTyping(true);
      }, 1500);
    }

    return () => clearTimeout(typingTimer);
  }, [isTyping, isErasing, currentWord]);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <div className="wrapper">
              <div className="txt-static-nav">Treze </div>
              <div className="txt-dynamic-nav">
                <span
                  style={{
                    animation: isTyping
                      ? "typing 3s steps(20) 1 forwards"
                      : isErasing
                      ? "erase 1.5s steps(20) 1 forwards"
                      : "none",
                  }}
                >
                  {currentWord}
                </span>
              </div>
            </div>
          </div>
          <nav className={`nav ${isNavOpen ? "open" : "closed"}`}>
            <ul>
              <li>
                <a href="/#home" onClick={closeNav}>
                  Início
                </a>
              </li>
              <li>
                <a href="/#servicos" onClick={closeNav}>
                  Serviços
                </a>
              </li>
              <li>
                <a href="/#productized-software" onClick={closeNav}>
                  Demonstrações
                </a>
              </li>
              <li>
                <a href="/#sobre" onClick={closeNav}>
                  Sobre
                </a>
              </li>
              <li>
                <a href="/#contato" onClick={closeNav}>
                  Contato
                </a>
              </li>
            </ul>
          </nav>
          <button className="menu-toggle" onClick={toggleNav}>
            &#9776;
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
