import React, { useState } from "react";
import "./services.css";

const Services = () => {
  const [expandedService, setExpandedService] = useState(null);

  const handleToggle = (service) => {
    setExpandedService(expandedService === service ? null : service);
  };

  return (
    <section id="servicos" className="services" data-aos="fade-up">
      <div className="container">
        <h2>Nossos Serviços</h2>
        <div
          className="service-item"
          onClick={() => handleToggle("webDevelopment")}
        >
          <i className="icon">🌐</i>
          <h3>Desenvolvimento Web</h3>
          <p>
            Sites e aplicações web de alta qualidade, personalizados para
            atender às suas necessidades específicas.
          </p>
          {expandedService === "webDevelopment" && (
            <div className="service-details">
              <h4>Sobre Desenvolvimento Web</h4>
              <p>
                O desenvolvimento web envolve a criação e manutenção de websites
                e aplicativos web. Inclui a criação de páginas, integração de
                banco de dados, otimização para motores de busca e muito mais.
                Nossa equipe cria soluções personalizadas para atender às suas
                necessidades específicas e garantir uma experiência de usuário
                excepcional.
              </p>
            </div>
          )}
        </div>
        <div
          className="service-item"
          onClick={() => handleToggle("mobileDevelopment")}
        >
          <i className="icon">📱</i>
          <h3>Desenvolvimento Mobile</h3>
          <p>
            Aplicativos móveis inovadores para Android e iOS, projetados para
            oferecer a melhor experiência ao usuário.
          </p>
          {expandedService === "mobileDevelopment" && (
            <div className="service-details">
              <h4>Sobre Desenvolvimento Mobile</h4>
              <p>
                O desenvolvimento mobile foca na criação de aplicativos para
                dispositivos móveis, como smartphones e tablets. Trabalhamos com
                plataformas Android e iOS para criar aplicativos que sejam
                rápidos, eficientes e atendam às expectativas dos usuários.
                Nossos aplicativos são projetados para oferecer uma experiência
                de usuário intuitiva e envolvente.
              </p>
            </div>
          )}
        </div>
        <div
          className="service-item"
          onClick={() => handleToggle("systemDevelopment")}
        >
          <i className="icon">🖥️</i>
          <h3>Desenvolvimento de Sistemas</h3>
          <p>
            Sistemas personalizados para otimizar processos internos e aumentar
            a eficiência do seu negócio.
          </p>
          {expandedService === "systemDevelopment" && (
            <div className="service-details">
              <h4>Sobre Desenvolvimento de Sistemas</h4>
              <p>
                O desenvolvimento de sistemas abrange a criação de softwares
                personalizados para atender às necessidades específicas de
                negócios e organizações. Isso pode incluir sistemas de
                gerenciamento, ferramentas de automação e muito mais. Nosso
                objetivo é ajudar a otimizar processos internos e aumentar a
                eficiência geral.
              </p>
            </div>
          )}
        </div>
        <div
          className="service-item"
          onClick={() => handleToggle("automation")}
        >
          <i className="icon">🤖</i>
          <h3>Automatização</h3>
          <p>
            Automatização de tarefas repetitivas, permitindo que sua equipe se
            concentre no que realmente importa.
          </p>
          {expandedService === "automation" && (
            <div className="service-details">
              <h4>Sobre Automatização</h4>
              <p>
                Automatização envolve o uso de tecnologia para realizar tarefas
                repetitivas e rotineiras sem intervenção humana. Isso pode
                incluir automação de processos de negócios, tarefas de TI e
                muito mais. O objetivo é aumentar a eficiência e permitir que
                sua equipe se concentre em tarefas mais estratégicas e
                criativas.
              </p>
            </div>
          )}
        </div>
        <div
          className="service-item"
          onClick={() => handleToggle("webScraping")}
        >
          <i className="icon">🔍</i>
          <h3>Web Scraping</h3>
          <p>
            Extração de dados da web de forma automatizada, fornecendo insights
            valiosos para suas decisões de negócios.
          </p>
          {expandedService === "webScraping" && (
            <div className="service-details">
              <h4>Sobre Web Scraping</h4>
              <p>
                Web scraping é uma técnica usada para extrair informações de
                sites. Usamos ferramentas e técnicas avançadas para coletar
                dados relevantes de forma automatizada, ajudando você a obter
                insights valiosos e a tomar decisões informadas com base nas
                informações coletadas.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Services;
