import React, { useState } from "react";
import "../Form.css";
import Modal from "./Modal";

const FormCorporate = () => {
  const [step, setStep] = useState(1);
  const [softwareType, setSoftwareType] = useState("");
  const [integrationNeeds, setIntegrationNeeds] = useState("");
  const [userCount, setUserCount] = useState("");
  const [customFeatures, setCustomFeatures] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div class="form-container" data-aos="fade">
      {step === 1 && (
        <>
          <p>
            <strong>Pergunta 2:</strong> Qual tipo de software corporativo você
            necessita?
            <br />
            <em>
              Selecione o tipo de software que melhor atende às necessidades da
              sua empresa.
            </em>
          </p>
          <select
            value={softwareType}
            onChange={(e) => setSoftwareType(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="erp">ERP (Enterprise Resource Planning)</option>
            <option value="crm">CRM (Customer Relationship Management)</option>
            <option value="hr">Sistema de Gestão de Recursos Humanos</option>
            <option value="custom">Outro (Personalizado)</option>
          </select>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}

      {step === 2 && (
        <>
          <p>
            <strong>Pergunta 3:</strong> Quais integrações são necessárias com
            sistemas existentes?
            <br />
            <em>
              Defina as integrações necessárias para que o novo software
              funcione perfeitamente com seus sistemas atuais.
            </em>
          </p>
          <select
            value={integrationNeeds}
            onChange={(e) => setIntegrationNeeds(e.target.value)}
            required
          >
            <option value="">Selecione</option>
            <option value="financial">Sistema Financeiro</option>
            <option value="inventory">Controle de Estoque</option>
            <option value="hrms">Sistemas de Recursos Humanos</option>
            <option value="custom">Outros Sistemas Personalizados</option>
          </select>
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}

      {step === 3 && (
        <>
          <p>
            <strong>Pergunta 4:</strong> Quantos usuários ou departamentos
            utilizarão o sistema?
            <br />
            <em>
              Saber o número de usuários ou departamentos ajuda a dimensionar o
              sistema adequadamente.
            </em>
          </p>
          <input
            type="number"
            value={userCount}
            onChange={(e) => setUserCount(e.target.value)}
            placeholder="Digite o número de usuários/departamentos"
            required
          />
          <button onClick={handlePreviousStep}>Anterior</button>
          <button onClick={handleNextStep}>Próximo</button>
        </>
      )}

      {step === 4 && (
        <>
          <p>
            <strong>Pergunta 5:</strong> Quais funcionalidades personalizadas
            você deseja?
            <br />
            <em>
              Especifique as funcionalidades que você gostaria de adicionar ao
              software corporativo.
            </em>
          </p>
          <textarea
            value={customFeatures}
            onChange={(e) => setCustomFeatures(e.target.value)}
            placeholder="Descreva as funcionalidades personalizadas desejadas..."
            rows="4"
            required
          />
          <button onClick={handlePreviousStep}>Anterior</button>
          <button type="submit" onClick={handleSubmit}>
            Enviar
          </button>
        </>
      )}
      <Modal
        Idform={2}
        showModal={showModal}
        setShowModal={setShowModal}
        atb1={softwareType}
        atb2={integrationNeeds}
        atb3={userCount}
        atb4={customFeatures}
      />
    </div>
  );
};

export default FormCorporate;
