
function detectDevice() {
  const userAgent = navigator.userAgent || window.opera;

  
  if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
    return "https://wa.me/553190005016?";
  }


  if (/tablet/i.test(userAgent)) {
    return "https://wa.me/553190005016?";
  }

  //Desktop
  return "https://web.whatsapp.com/send/?phone=553190005016&";
}

export default detectDevice;
