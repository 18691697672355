import React from "react";
import "./FormCall.css";
import { useNavigate } from "react-router-dom";

const FormCall = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/form");
  };

  return (
    <div id="chamada-form" className="formCall" data-aos="fade-up">
      <h2>Descubra o melhor software para seu negócio</h2>
      <h3>
        Quer saber como um software totalmente personalizado para seu negócio
        pode alavancar seu negócio?
      </h3>
      <p>
        Clique no botão abaixo e entenda qual dos nossos serviços mais faz
        sentido para alavancar sua empresa.
      </p>
      <button type="submit" onClick={handleSubmit}>
        Quero descobrir!
      </button>
    </div>
  );
};

export default FormCall;
