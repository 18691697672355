import React from "react";
import "./productizedSoftware.css";

const ProductizedSoftware = () => {
  const softwareDetails = {
    name: "Software de Agendamento",
    description: "Um software de agendamento desenvolvido com base em uma estrutura já estabelecida, permitindo rápido desenvolvimento e customização.",
    features: [
      "Agendamento fácil e intuitivo",
      "Integração com Google Calendar",
      "Notificações automáticas por e-mail e SMS",
      "Pagamento online seguro"
    ],
    demoLink: "https://demonstracao-agendamento.vercel.app/" 
  };

  return (
    <section id="productized-software" className="productized-software" data-aos="fade-up">
      <div className="container">
        <h2>Softwares Produtizados</h2>
        <p>
          Softwares produtizados são soluções de desenvolvimento rápido que seguem uma base pré-estabelecida, permitindo uma personalização eficiente e ágil. 
          Eles são criados para atender a necessidades comuns e podem ser adaptados para diferentes contextos com rapidez, reduzindo o tempo e o custo de desenvolvimento.
        </p>
        <div className="software-item">
          <h3>{softwareDetails.name}</h3>
          <p>{softwareDetails.description}</p>
          <ul>
            {softwareDetails.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <a href={softwareDetails.demoLink} className="demo-link" target="_blank" rel="noopener noreferrer">
            Ver Demonstração
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProductizedSoftware;
